.header {
    position: relative;
}

.header header {
    padding: 0 50px;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 150px;
    border-top: 5px solid #F6F6ED;
    border-bottom: 5px solid #F6F6ED;
}

.header-title {
    font-size: 118px;
    font-weight: 700;
    text-align: left;
    text-transform: uppercase;
    letter-spacing: 20px;
}

@media screen and (max-width: 1200px) {
    .header header {
        height: 110px;
    }

    .header-title {
        font-size: 80px;
    }
}

@media screen and (max-width: 907px) {
    .header-title {
        font-size: 55px;
    }
}

@media screen and (max-width: 836px) {
    .header header {
        height: 140px;
        padding: 0 40px;
    }
}