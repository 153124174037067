@import url('https://fonts.googleapis.com/css2?family=Victor+Mono:wght@400;700&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Tsukimi+Rounded:wght@400;700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Quattrocento:wght@400;700&display=swap'); */

body {
  margin: 0;
  padding: 0;
  font-family: 'Victor Mono', monospace;
  /* font-family: 'Tsukimi Rounded', sans-serif; */
  /* font-family: 'Quattrocento', serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #5F8368;
  color: #F6F6ED;
}

h2 {
  font-size: 1.5rem;
}

.font-browser {
  font-size: 14px;
}

.font-browser-sm {
  font-size: 12px;
}

.font-mobile {
  font-size: 10px;
}

.h-60 {
  height: 60%;
}

.px-50 {
  padding: 0 50px;
}

.text-center {
  text-align: center;
}

.overflow-hidden {
  overflow: hidden;
}

.table-width {
  max-width: 400px;
}
