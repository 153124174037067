.profile {
  height: 80vh;
  border-right: 5px solid #F6F6ED;
}

.profile-img-browser {
  padding: 50px;
  height: 40%;
}

.profile-img-mobile {
  padding: 50px;
  height: 80vw;
  display: block;
  margin: 0 auto;
}

.profile-container {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}

.profile-text {
  padding: 0 50px;
  line-height: 25px;
  white-space: pre-line;
}