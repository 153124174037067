.service {
    width: 100%;
    height: 80vh;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    border-right: 5px solid #F6F6ED;
}

.service-title {
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 3px;
}

