.contact-browser {
    height: 43vh;
    padding: 0 60px;
    margin-left: -12px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    border-bottom: 5px solid #F6F6ED;
}

.contact-mobile {
    padding: 50px 60px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    border-bottom: 5px solid #F6F6ED;
}

.contact-text {
    max-width: 240px;
}

.phone-number {
    font-weight: 700;
    letter-spacing: 3px;
}

.social-media {
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 3px;
}

.social-media > a {
    text-decoration: none;
    color: inherit;
}

.hr {
    display: block;
    width: 70%;
    margin-top: 1rem;
    margin-bottom: 2rem;
    border-bottom: 3px solid #F6F6ED;
}