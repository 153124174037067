.app {
  width: 100vw;
  height: 100vh;
  letter-spacing: 1px;
}

.table>:not(caption)>*>* {
  color: unset;
  background-color: unset;
  border-bottom-width: 0;
}

.h-20 {
  height: 20vh;
}

.h-80 {
  height: 80vh;
}