.hours-browser {
    height: 43vh;
    padding: 0 60px;
    margin-left: -12px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    border-bottom: 5px solid #F6F6ED;
    font-size: 14px;
}

.hours-mobile {
    padding: 0 60px 50px 90px;
    border-bottom: 5px solid #F6F6ED;
    font-size: 10px;
}

.hours-title {
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 3px;
}